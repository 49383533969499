var klickartPerformantAccordion = function () {
  var accordions = document.querySelectorAll(".faq-questions");
  if (!accordions) {
    return;
  }
  function setHeights(accordion) {
    var items = accordion.querySelectorAll(".faq-question.faq-accordion");
    for (var index = 0; index < items.length; index += 1) {
      var currentQuestionHeight = items[index].querySelector(
        "[klickart-edit-faq-question]"
      ).clientHeight;
      items[index].style.maxHeight = `${currentQuestionHeight}px`;
    }
  }
  function setNormalizedCss(accordion) {
    var items = accordion.querySelectorAll(".faq-question.faq-accordion");
    for (var index = 0; index < items.length; index += 1) {
      var arrow = items[index].querySelector(".arrow");
      var content = items[index].querySelector(".content");
      var question = items[index].querySelector("[klickart-edit-faq-question]");
      items[index].style.transition = "all 0.4s";
      items[index].style.overflow = "hidden";
      question.style.margin = 0;
      content.style.display = "block";
      if (arrow) {
        arrow.style.transition = "all 0.4s";
      }
    }
  }
  function rotateArrow(item, deg) {
    var arrow = item.querySelector(".arrow");
    if (arrow) {
      arrow.style.transform = `rotate(${deg}deg)`;
    }
  }
  function openItem(item) {
    var arrow = item.querySelector(".arrow");
    var plus = item.querySelector(".fl-faq-plus");
    if (arrow) {
      arrow.classList.add("open");
      rotateArrow(item, 180);
    }
    if (plus) {
      var separator = item.querySelector(".fl-faq-separator");
      plus.classList.add("open");
      separator.classList.add("open");
    }
    item.style.maxHeight = `${item.scrollHeight + 1}px`;
  }
  function closeItem(item, maxHeight) {
    var arrow = item.querySelector(".arrow");
    var plus = item.querySelector(".fl-faq-plus");
    if (arrow) {
      arrow.classList.remove("open");
      rotateArrow(item, 0);
    }
    if (plus) {
      var separator = item.querySelector(".fl-faq-separator");
      plus.classList.remove("open");
      separator.classList.remove("open");
    }
    item.style.maxHeight = `${maxHeight}px`;
  }
  var lastClicked;
  function toggleItem(event) {
    var currentClicked = event.currentTarget;
    if (lastClicked) {
      var currentQuestion = lastClicked.querySelector(
        "[klickart-edit-faq-question]"
      );
      var currentQuestionHeight = currentQuestion.clientHeight;
      rotateArrow(lastClicked, 0);
      closeItem(lastClicked, currentQuestionHeight);
    }
    if (currentClicked.clientHeight !== currentClicked.scrollHeight) {
      openItem(currentClicked);
    }
    lastClicked = currentClicked;
  }
  function setListeners(accordion) {
    var items = accordion.querySelectorAll(".faq-question.faq-accordion");
    for (var index = 0; index < items.length; index += 1) {
      items[index].addEventListener("click", toggleItem);
    }
  }
  for (var index = 0; index < accordions.length; index += 1) {
    const accordion = accordions[index];
    setNormalizedCss(accordion);
    setHeights(accordion);
    setListeners(accordion);
  }
};

export default klickartPerformantAccordion;
